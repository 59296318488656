import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/colors';

const BoldTitle = ({children, ...otherProps}) => {
	return ( 
		<Title {...otherProps}>{children}</Title>
	 );
}
 
export default BoldTitle;

const Title = styled.h1`
font-family: "Circular Std Bold", Lato, sans-serif;
font-size: ${({size}) => size ? size : '18px'};
color: ${({color}) => color ? color : Colors.primary};
margin-bottom: 0;
text-align: ${({ align }) => align? align : 'center'};

@media(min-width: 1200px){
	font-size: 44px;
}
`;