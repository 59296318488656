import React from 'react';
import styled from 'styled-components';
import BoldTitle from '../components/bold-title/boldTitle.component';
import thanksIcon from '../images/thanksIcon.png';
import { Colors } from '../utils/colors';
import CustomButton from '../components/custom-button/custom-button.component';
import { navigate } from 'gatsby';

const VerifyEmailMessage = () => {
	
	return ( 
			<Container>
				<BoldTitle color={Colors.tertiary}>Go to your email to verify your account</BoldTitle>
				<Subtitle>You won't be able to use the service unless your email is verified</Subtitle>
				<Img src={thanksIcon} />
				<CustomButton width='200px' background={Colors.primary} color={Colors.white} onClick={() => navigate("/")}>Return Home</CustomButton>
			</Container>
	 );
}
 
export default VerifyEmailMessage;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 30px;
padding: 20px;
`;



const Subtitle = styled.h3`
font-size: 14px;
font-weight: 300;
color: ${Colors.parlor_grey};
margin-top: 8px;

@media(min-width: 1200px){
	font-size: 18px;
}
`;

const Img = styled.img`
display: block;
margin-top: 40px;
width: 300px;
margin-bottom: 40px;
`;